











import {Vue, Component} from "vue-property-decorator";
import {DistributionUser} from "@/request/customer/DistributionUser";

@Component({
    name: 'customer_distributionUser'
})
export default class customer_distributionUser extends Vue {
    modal: any = DistributionUser;
    search: Array<any> = [
        {
            type: 'Input',
            label: '电话',
            key: 'phone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '注册时间',
            width: '210px',
            placeholder: '请选择注册时间'
        }
    ];
    columns: Array<any> = [
        {
            title: '昵称',
            key: 'nickName',
            minWidth: 140,
            tooltip: true
        },
        {
            title: '头像',
            key: 'avatarUrl',
            minWidth: 80,
            render: (h: any, params: any) => {
                return h('img', {
                    style: {
                        borderRadius: '3px',
                        height: '40px',
                        width: '40px',
                        marginTop: '5px'
                    },
                    attrs: {
                        src: params.row.avatarUrl
                    }
                })
            }
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '所得金额',
            key: 'distributionTotalFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.distributionTotalFee))
        },
        {
            title: '当前金额',
            key: 'distributionCurFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.distributionCurFee))
        },
        {
            title: '已提金额',
            key: 'withdrawDepositFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.withdrawDepositFee))
        },
        {
            title: '未入账',
            key: 'notEnterFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.notEnterFee))
        },
        {
            title: '一级分拥订单笔数',
            key: 'oneDistributionOrders',
            minWidth: 150
        },
        {
            title: '一级发展人数',
            key: 'oneLevelPersons',
            minWidth: 140
        },
        {
            title: '二级分拥订单笔数',
            key: 'twoDistributionOrders',
            minWidth: 150
        },
        {
            title: '二级发展人数',
            key: 'twoLevelPersons',
            minWidth: 140
        },
        {
            title: '加入分销时间',
            key: 'partnerTime',
            minWidth: 180
        }
    ];
    created() {

    }
}
